import {
  BulidingAutomate,
  CompressedAir,
  EVandChargingEquipment,
  Foodservice,
  GasFlame,
  Horticultural,
  HVAC,
  LightingandControls,
  Renewables,
  ResidentialProducts,
  WaterEfficiency
} from "../assests/category";

export const Icons = {
  "Buliding Automate": BulidingAutomate,
  "EV and Charging Equipment": EVandChargingEquipment,
  "Compressed Air": CompressedAir,
  "Foodservice": Foodservice,
  "Gas Flame": GasFlame,
  "Horticultural": Horticultural,
  "HVAC": HVAC,
  "Lighting and Controls": LightingandControls,
  "Renewables": Renewables,
  "Residential Products": ResidentialProducts,
  "Water Efficiency": WaterEfficiency,
};
