/* eslint-disable react/jsx-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react'
import PropTypes, { number } from 'prop-types'
import cn from 'classnames'
// import _ from "lodash"
import moment from "moment"
import { toastr } from 'react-redux-toastr'
import { IconCloseDrawer, IconArrow, IconCloseDrawerMobile, IconDrawerFollow, IconDrawerLink, IconCategory5, IconRX, IconCategoryHouse, IconCategoryHammer, IconCalCulate, IconThunder, IconRebateUrl } from "../../../assests/icons"
import { StatusText, Tooltip, Button } from ".."
import { Icons } from "../../../constants/categories";
import { IconsStates } from "../../../constants/states"
import { IconQplsActive, IconQplsInActive } from "../../../constants/qpls"
import { IconCustomerInActive, IconCustomerActive } from "../../../constants/customers"
import { handleRenderCardName, numberToPrice, checkAuthorize, handleGotoRebateWebsite, inIframe, nanoid } from "../../../utils/common"
import { NewMember } from "../../../constants/common"
import ApplyRebateModal from "../../modules/ApplyRebateModal";
import ModalPremium from "../../modules/ModalPremium";
import { useAuth0 } from "../../Auth0";
import './style.scss'
import { Col, Row } from 'react-bootstrap'
import EstimateRebateValueModal from '../EstimateRebateValueModal'
import { formatDate } from 'utils/dates'
import { useTranslation } from "react-i18next"

const DrawerUtilites = (props) => {
  const {
    className,
    isShow,
    onClose,
    data,
    onClick,
    indexActive,
    itemData,
    qpls,
    categories,
    reloadingRebate
  } = props
  const newCategories = categories.filter(item => item.rebateNumber > 0)
  const [t] = useTranslation();

  const DrawerClasses = cn(
    'drawer-utility',
    'custom-Drawer',
    [className],
  )
  const [showTrialModal, setShowTrialModal] = useState(false);
  const [showEstimateModal, setShowEstimateModal] = useState(false)
  const { name = 'Iowa', utility = {}, items = [], categoryRoots = [] } = data
  const IconMapImage = IconsStates[name] ? IconsStates[name] : null
  const { name: utilityName, websiteUrl = '', modified = '', created = '' } = utility;

  const [updateReview, setUpdateReview] = useState()
  // const [lastReview, setLastReview] = useState()
  const [lastUpdateTime, setLastUpdateTime] = useState('-');
  const [lastUpdateTimeAbs, setLastUpdateTimeAbs] = useState('-')

  const [objectEl, setObjectEl] = useState({})

  const [showApplyRebateModal, setShowApplyRebateModal] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);

  const { user = {} } = useAuth0();
  const indexAuthorizeAdmin = checkAuthorize(user)
  const { userType } = user;

  useEffect(() => {
    if (modified || updateReview) {
      setLastUpdateTime(moment(updateReview || modified).startOf('month').fromNow())
      setLastUpdateTimeAbs(moment(updateReview || modified).format("ll"))
    } else {
      setLastUpdateTime(null)
      setLastUpdateTimeAbs(null)
    }

  }, [updateReview, modified])

  function handleRenderStatus(isActive) {
    if (isActive) {
      return 'active'
    } else if (typeof isActive === 'object') {
      return 'upcoming'
    }
    return 'closed'
  }

  function copyToClipboard(text) {
    let selected = false;
    const el = document.createElement('textarea');
    el.value = text;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    if (document.getSelection().rangeCount > 0) {
      selected = document.getSelection().getRangeAt(0)
    }
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    if (selected) {
      document.getSelection().removeAllRanges();
      document.getSelection().addRange(selected);
    }
  }

  const renderRebateRow = (item) => {
    const { name, expirationDate, minCustomerContribution, websiteUrl, rebateValue, notes } = item;
    const { value, units } = rebateValue

    const renderItemName = () => {
      return (
        <span className={`rebate-name ${websiteUrl ? 'hover-cursor' : ''}`} onClick={() => handleGotoRebateWebsite(websiteUrl)}>{name}
          {
            websiteUrl &&
            <IconRebateUrl
              className="hover-cursor"
              onClick={() => handleGotoRebateWebsite(websiteUrl)}
            />
          }
        </span>
      )
    }

    return (
      <Row key={item.id} className="rebate-row">
        <Col xs={7} sm={7} md={7} lg={7} xl={7} className="center-text-div left-all-text">
          <Row className="rebate-row-spacing">
            {
              notes ? (
                <Tooltip key={item.id} childrenTooltip={notes} >
                  {
                    renderItemName()
                  }
                </Tooltip>
              ) : (
                renderItemName()
              )
            }
          </Row>
          {
            (expirationDate || (minCustomerContribution !== null && !isNaN(minCustomerContribution))) ? (
              <Row className="rebate-row-spacing">
                {
                  expirationDate ? (
                    <span className="rebate-expires">
                      Expires: {moment(expirationDate).format('MM-DD-YY')}
                    </span>
                  ) : (null)
                }
                {
                  (minCustomerContribution !== null && !isNaN(minCustomerContribution)) ? (
                    <span className="rebate-contribution">
                      Minimum Contribution: {minCustomerContribution}
                    </span>
                  ) : (null)
                }
              </Row>
            ) : (null)
          }
        </Col>
        <Col xs={5} sm={5} md={5} lg={5} xl={5} className="center-text-div center-all-text">
          <div className="rebate-value rebate-row-spacing">
            <p>
              $ {!Number.isInteger(value) ? value.toFixed(3) : value}
              <sub className="rebate-value-sub">per</sub>
            </p>
          </div>
          <div className="rebate-value-units">
            {units}
          </div>
        </Col>
      </Row>
    )
  }

  const handleClickShowMore = (el, indexNow) => {
    // pageIndex
    const pageIndexData = pageIndex + 1;
    setPageIndex(pageIndexData);
    onClick(el, indexNow, pageIndexData);
  }

  return (
    <>
      <div className={DrawerClasses} >
        <div className={cn("custom-Drawer custom-custom-Drawer", { toggled: !isShow })}>
          <div className="custom-custom-Drawer__header custom-custom-Drawer__header-seond" >
            <div className="row">
              <div className="col-8 col-md-6">
                <div className="custom-custom-Drawer__time">
                  <div className="custom-custom-Drawer__time__parent">
                    {
                      lastUpdateTime ? (
                        <>
                          <div className="custom-custom-Drawer__time__text">Last Updated {lastUpdateTime}</div>
                          <div className="custom-custom-Drawer__time__text-sub">{lastUpdateTimeAbs}</div>
                        </>
                      ) : null
                    }
                  </div>
                  <div className="custom-custom-Drawer__time__line hiddenMobile" />
                </div>
              </div>
              <div className="col-4 col-md-6">
                <div className="custom-custom-Drawer__groupIcon">
                  <IconDrawerFollow className="custom-custom-Drawer__header__icon  hiddenMobile" />
                  <IconDrawerLink onClick={() => {
                    const urlReview = window.location
                    copyToClipboard(urlReview)
                    toastr.success("Direct link copied to clipboard.")

                  }} className="custom-custom-Drawer__header__icon hiddenMobile custom-custom-Drawer__header__icon--copy" />

                  <IconCloseDrawer onClick={() => { onClose() }} className="custom-custom-Drawer__header__icon hiddenMobile" />
                  <IconCloseDrawerMobile onClick={() => { onClose() }} className="custom-custom-Drawer__header__icon hiddenDesktop" />
                </div>
              </div>
            </div>
          </div>
          <div className="custom-custom-Drawer__title">
            <div>
              {IconMapImage ? <IconMapImage /> : null}
            </div>
            <div className="custom-custom-Drawer__title__parent">
              <div className="custom-custom-Drawer__title__text">{name}</div>
              <div className="custom-custom-Drawer__title__text-sub">{utilityName}</div>
              {
                websiteUrl && websiteUrl !== '' ? (
                  <div onClick={() => handleGotoRebateWebsite(websiteUrl)}
                    className="custom-custom-Drawer__title__text-link">Utility website <IconCategory5 /></div>
                ) : null
              }
            </div>
          </div>
          {
            !items || items.length === 0 ? (
              <div className="none-program-text">
                <span>
                  {utilityName} Has No Programs Available
                </span>
              </div>
            ) : (
              <div className="custom-Drawer-content">
                <div className="custom-Drawer-content__container">
                  <div className="custom-Drawer-content__title">Rebate Programs</div>
                  {/* <Button onClick={() => { setShowEstimateModal(true) }} className="topFilterTable__button topFilterTable__button-add " typeClass="second" size="md" text={"Estimate Rebate Value"} /> */}
                </div>
                {
                  items && items.length > 0 && (
                    items.map((el, index) => {
                      const {
                        isActive, maxAnnualIncetive = ' n/a',
                        isRetroFit, isNewConstruction, isInstant,
                        incentiveCap, capType, isLastRebateValue, allRebateCount, allRebateFilteredCount,
                        programCustomerTypes = [], rebates = [], isCustomFilteredRebate
                      } = itemData;

                      const endDateValue = formatDate(el.endDate);
                      const preApprovalRequired = el.preApprovalRequiredAmount === 0 ? 'No' : el.preApprovalRequiredAmount === 1 ? 'Yes' : `$${el.preApprovalRequiredAmount.toLocaleString()}`
                      const newData = []
                      if (rebates) {
                        rebates.forEach(item => {
                          const { rebateType } = item
                          const index = newData.findIndex(el => el === rebateType)
                          if (index === -1) {
                            newData.push(rebateType)
                          }
                        })
                      }

                      return (
                        <React.Fragment key={nanoid()}>
                          <div onClick={() => {
                            const indexNow = index === indexActive ? -1 : index;
                            onClick(el, indexNow, 0);
                            if (indexNow !== -1) {
                              //setLastReview(el.created)
                              setUpdateReview(el.modified)
                            } else {
                              //setLastReview(null)
                              setUpdateReview(null)
                            }
                            setPageIndex(0)
                          }} key={`${el.name}-drawer`} className={`custom-Drawer-content__item
                              ${((index !== indexActive) && index === (items.length - 1)) && 'last-custom-Drawer-content__item'}`}>
                            <div className="custom-Drawer-content__item-text custom-Drawer-content__item-text-second">
                              <IconArrow className={`custom-Drawer-content__item-text-second__icon ${index === indexActive ? "active" : ""}`} />
                              <div className={`custom-Drawer-content__item-text-second__parent  ${index === indexActive ? "active" : ""}`}>
                                {handleRenderCardName(el.name)}
                                {el.websiteLink && !inIframe() ? (
                                  <div onClick={() => {
                                    if (!indexAuthorizeAdmin && (!userType || userType === NewMember)) {
                                      setShowTrialModal(true)
                                    } else {
                                      setShowApplyRebateModal(true)
                                    }
                                    setObjectEl(el)
                                  }} className="custom-custom-Drawer__title__text-link custom-custom-Drawer__title__text-link-second">Apply for rebate <IconCategory5 />
                                  </div>
                                ) : null}
                              </div>

                            </div>
                          </div>
                          <div className={`custom-Drawer-content__child ${index === indexActive ? "custom-Drawer-content__child-active" : ""}`}>
                            <div className="row rebate-information">
                              <div className="col-12 col-md-7">
                                <div className="row">
                                  <div className="col-6 custom-Drawer-content__child__lable" >Status</div>
                                  <div className="col-6">
                                    <StatusText status={handleRenderStatus(isActive)} size="sm" >{handleRenderStatus(isActive)}</StatusText>
                                  </div>
                                </div>
                                {/* <div className="custom-Drawer-content__child__group">
                                  <div className="row ">
                                    <div className="col-6 custom-Drawer-content__child__lable" >QPLs</div>
                                    <div className="col-6">
                                      {
                                        Object.keys(IconQplsInActive).map(key => {
                                          const index = qpls.findIndex(item => item.qplType === key)
                                          let newId = ''
                                          if (index !== -1) {
                                            newId = qpls[index]
                                          }
                                          const index2 = rebates.findIndex(el2 => el2.categories.find(el3 => el3.categorySource === key))
                                          const Ico = index2 !== -1 ? IconQplsActive[key] : IconQplsInActive[key]
                                          return (
                                            <Tooltip key={key} childrenTooltip={key} >
                                              <Ico />
                                            </Tooltip>
                                          )
                                        })
                                      }
                                    </div>
                                  </div>
                                </div> */}


                                <div className="custom-Drawer-content__child__group">
                                  <div className="row ">
                                    <div className="col-6 custom-Drawer-content__child__lable" >Categories </div>
                                    <div className="col-6">

                                      {newCategories.map(item => {
                                        const Ico = Icons[item.name] || null
                                        const index = categoryRoots.findIndex(el2 => el2.name === item.name)
                                        return (
                                          <>
                                            {Ico ? (
                                              <Tooltip key={item.name} childrenTooltip={item.name} >
                                                <div className="tooltip-drawer">
                                                  <Ico key={item.name} className={`${index !== -1 ? "category-active" : " "}`} />
                                                </div>
                                              </Tooltip>
                                            ) : null}
                                          </>
                                        )
                                      })}
                                    </div>
                                  </div>
                                </div>
                                <div className="custom-Drawer-content__child__group custom-Drawer-content__child__group-second">
                                  <div className="row ">
                                    <div className="col-6 custom-Drawer-content__child__lable" >Rebate Type</div>
                                    <div className="col-6">

                                      {newData.map((el, index) => {
                                        if (el === 'Calculated') {
                                          return (
                                            <Tooltip key={index} childrenTooltip="Calculated" >
                                              <div className="tooltip-drawer">
                                                <IconCalCulate className="category-active" />
                                              </div>
                                            </Tooltip>
                                          )
                                        } else {
                                          return (
                                            <Tooltip key={index} childrenTooltip="Prescriptive" >
                                              <div className="tooltip-drawer">
                                                <IconRX className="category-active" />
                                              </div>
                                            </Tooltip>
                                          )
                                        }
                                      })}
                                    </div>
                                  </div>
                                  <div className="row ">
                                    <div className="col-6 custom-Drawer-content__child__lable" >Project Type</div>
                                    <div className="col-6">
                                      {isNewConstruction ? (
                                        <Tooltip childrenTooltip="New Construction" >
                                          <div className="tooltip-drawer">
                                            <IconCategoryHouse className="category-active" />
                                          </div>
                                        </Tooltip>
                                      ) : null}
                                      {isRetroFit ? (
                                        <Tooltip childrenTooltip="Retrofit" >
                                          <div className="tooltip-drawer">
                                            <IconCategoryHammer className="category-active" />
                                          </div>
                                        </Tooltip>
                                      ) : null}
                                      {
                                        !!isInstant && (
                                          <Tooltip childrenTooltip="Instant" >
                                            <div className="tooltip-drawer">
                                              <IconThunder className="category-active" />
                                            </div>
                                          </Tooltip>
                                        )
                                      }
                                    </div>
                                  </div>
                                  <div className="custom-Drawer-content__child__group">
                                    <div className="row ">
                                      <div className="col-6 custom-Drawer-content__child__lable" >End Date</div>
                                      <div className="col-6">
                                        {endDateValue}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="custom-Drawer-content__child__group">

                                    <div className="row ">
                                      <div className="col-6 custom-Drawer-content__child__lable" >{t("program.pre_approval_required")}</div>
                                      <div className="col-6">
                                        {preApprovalRequired}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                              </div>
                              <div className="col-12 col-md-5">
                                <div className="custom-Drawer-content__child__group-third">
                                  <div className="row">
                                    <div className="col-6 custom-Drawer-content__child__lable" >Max Annual Incentive</div>
                                    <div className="col-6">
                                      {maxAnnualIncetive ? `$${numberToPrice(maxAnnualIncetive)}` : 'n/a'}
                                    </div>
                                  </div>
                                </div>
                                <div className="custom-Drawer-content__child__group custom-Drawer-content__child__group-second">
                                  <div className="row ">
                                    <div className="col-6 custom-Drawer-content__child__lable" >Project Caps</div>
                                    <div className="col-6">
                                      {capType === 'ProjectCap' ? `${incentiveCap}%` : 'n/a'}
                                    </div>
                                  </div>

                                  <div className="row ">
                                    <div className="col-6 custom-Drawer-content__child__lable" >Material Caps</div>
                                    <div className="col-6">
                                      {capType === 'MaterialCap' ? `${incentiveCap}%` : 'n/a'}
                                    </div>
                                  </div>
                                  <div className="row ">
                                    <div className="col-6 custom-Drawer-content__child__lable" >Labor Caps</div>
                                    <div className="col-6">
                                      {capType === 'LaborCap' ? `${incentiveCap}%` : 'n/a'}
                                    </div>
                                  </div>
                                </div>
                                <div className="custom-Drawer-content__child__group ">
                                  <div className="row ">
                                    <div className="col-6 custom-Drawer-content__child__lable" >Customers</div>
                                    <div className="col-6">
                                      {
                                        Object.keys(IconCustomerInActive).map(key => {
                                          const index = programCustomerTypes.findIndex(el2 => el2.customerType && el2.customerType.name === key)
                                          const Ico = index !== -1 ? IconCustomerActive[key] : IconCustomerInActive[key]
                                          return (
                                            <Tooltip key={key} childrenTooltip={key} >
                                              <div className="tooltip-drawer">
                                                <Ico />
                                              </div>
                                            </Tooltip>
                                          )
                                        })
                                      }
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {
                              !reloadingRebate && (
                                <>
                                  {
                                    !!(allRebateCount && allRebateCount >= 0) && (
                                      <div className="rebate-count">
                                        <span className="rebate-count-text">
                                          {
                                            !isCustomFilteredRebate ? (
                                              `${allRebateCount} ${allRebateCount <= 1 ? 'Rebate' : 'Rebates'}`
                                            ) : (
                                              `Showing ${allRebateFilteredCount} of ${allRebateCount} Rebates matching your filters`
                                            )
                                          }
                                        </span>
                                      </div>
                                    )
                                  }
                                  <div className="col rebate-container">
                                    {
                                      rebates.map((itemRebate) => {
                                        return renderRebateRow(itemRebate);
                                      })
                                    }
                                  </div>
                                  {
                                    !isLastRebateValue && (
                                      <div className="button-show-more-rebate" onClick={() => {
                                        handleClickShowMore(el, index)
                                      }}>
                                        SHOW MORE
                                      </div>
                                    )
                                  }
                                </>
                              )
                            }
                          </div>
                        </React.Fragment>
                      )
                    })
                  )
                }
              </div>
            )
          }
        </div>
      </div>
      <ApplyRebateModal
        utilityName={utilityName}
        programName={objectEl.name}
        onClickLink={() => {
          if (objectEl.websiteLink) {
            const { websiteLink } = objectEl
            const newUrl = websiteLink.startsWith("http://") || websiteLink.startsWith("https://") ? websiteLink : `https://${websiteLink}`
            window.open(newUrl, '_blank')
          }
        }} show={showApplyRebateModal} onHide={() => setShowApplyRebateModal(false)} />
      <ModalPremium show={showTrialModal} onHide={() => { setShowTrialModal(false) }} actionClick={() => { setShowTrialModal(false) }} />
      <EstimateRebateValueModal
        show={showEstimateModal}
        setShowEstimateModal={setShowEstimateModal}
        utilityName={utilityName}
        programName={objectEl.name}
        utilityId={utility.id}
        onClickLink={() => {
          if (objectEl.websiteLink) {
            const { websiteLink } = objectEl
            const newUrl = websiteLink.startsWith("http://") || websiteLink.startsWith("https://") ? websiteLink : `https://${websiteLink}`
            window.open(newUrl, '_blank')
          }
        }}
        showApplyRebateModal={showApplyRebateModal}
        onHideApplyRebateModal={() => setShowApplyRebateModal(false)}
        indexAutherizeAdmin={indexAuthorizeAdmin}
        userType={userType}
        setShowTrialModal={setShowTrialModal}
        setShowApplyRebateModal={setShowApplyRebateModal}
        setObjectEl={el => setObjectEl(el)}
      />
    </>
  )
}

DrawerUtilites.propTypes = {
  className: PropTypes.string,
  isShow: PropTypes.bool,
  onClose: PropTypes.func,
  data: PropTypes.object,
  onClick: PropTypes.func,
  indexActive: PropTypes.number,
  itemData: PropTypes.object,
  qpls: PropTypes.array,
  categories: PropTypes.array,
  reloadingRebate: PropTypes.bool,
}

DrawerUtilites.displayName = 'Drawer'

DrawerUtilites.defaultProps = {
  className: "",
  isShow: false,
  onClose: () => { },
  data: {},
  onClick: () => { },
  indexActive: -1,
  itemData: {},
  qpls: [],
  categories: [],
  reloadingRebate: false
}

export default DrawerUtilites
